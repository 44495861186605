.nav-brdr{
  border-bottom: 2px solid rgba(12, 12, 77, 0.582);
  
}

button.btn {
    background: none;
    border: 2px solid white !important;
    color: white !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.6s;
  }
  
  button.btn:hover {
    background-color: #ffffff;
    color: #06183bb7 !important;
    box-shadow: 0 0 10px #472a7ea8 !important;
    transform: translateY(-2px);
    border-color: #472a7ea8 !important; /* Purple border color */
    font-weight: bold; /* Bold font weight */
  }
  
  button.btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    transition: all 0.4s;
  }
  
  button.btn:hover::before {
    left: 0;
    background-color: #fff;
    z-index: -1;
  }
  