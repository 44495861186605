.signup-card {
    background-color: #cbbdf859;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .signup-card h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .signup-card label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .signup-card input[type="email"],
  .signup-card input[type="password"] {
    border: none;
    border-bottom: 2px solid #4a90e2;
    border-radius: 0;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    color: #333333;
    background-color: transparent;
  }
  
  .signup-card input[type="email"]::placeholder,
  .signup-card input[type="password"]::placeholder {
    color: #4a90e2;
    font-size: 14px;
    font-style: italic;
    background-color: #f7f7f7;
  }
  
  .signup-card input[type="email"]:focus,
  .signup-card input[type="password"]:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 10px rgba(74, 144, 226, 0.6);
  }
  
  form > button {
    background-color: #0e3f5fde;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    width: 100% !important;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.8s;
  }
  
  form > button:hover {
   
    background-color: #ffffff;
    color: #071d46b7;
    box-shadow: 0 0 10px #472a7ea8;
    transform: translateY(-2px);
    font-weight: bold;
  }
  
  form > button::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    transition: all 0.4s;
  }
  
  form > button:hover::before {
    left: 0;
    background-color: #fff;
    z-index: -1;
  }
  
  form > button:hover span {
    color: #472a7e;
    font-weight: bold !important;
  }
  
  form > button span {
    transition: all 0.8s;
  }
  form>p{
    color: white;
  }
  .signe-btn{
    background: none;
    border: none;
    text-decoration: underline;
    color:  #0e3f5fde;
  }
  .signe-btn:hover{
    color: white;
   
  }
  

  @media screen and (max-width: 480px) {
    .signup-card {
      max-width: 300px;
    }
  }
  