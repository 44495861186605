.form-card {
    background-color: #cbbdf859;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .form-card h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: rgba(14, 17, 70, 0.705);
  }
  
  .form-card label {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .form-card input[type="text"],
  .form-card textarea {
    border: none;
    border-bottom: 2px solid #4a90e2;
    border-radius: 0;
    box-sizing: border-box;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    color: #333333;
    background-color: transparent !important;
  }
  
  .form-card input[type="text"]::placeholder,
  .form-card textarea::placeholder {
    color: #4a90e2;
    font-size: 14px;
    font-style: italic;
    background-color: transparent !important;
  }
  
  .form-card input[type="text"]:focus,
  .form-card textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 10px rgba(74, 144, 226, 0.6);
  }
  
  .form-card button[type="submit"] {
    background-color: #0e3f5fde;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    width: 100% !important;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.8s;
  }
  
  .form-card button[type="submit"]:hover {
    background-color: #ffffff;
    color: #071d46b7;
    box-shadow: 0 0 10px #472a7ea8;
    transform: translateY(-2px);
    font-weight: bold;
  }
  
  .form-card button[type="submit"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #0e3f5fde;
    z-index: -1;
    transition: all 0.8s;
  }
  
  .form-card button[type="submit"]:hover::before {
    left: 0;
  }

// output div 

.todo-lists {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #f4f4f7a9;
  }
  
  th {
    color: white !important;
    background-color: #0413319d !important;
  }
  





